import * as React from "react";
import Layout from "../components/Layout";
import HeroImage from "../components/Hero";
import { css } from "@emotion/react";
import FloorPlanComponet from "../components/floorplan";
import GalleryComponent from "../components/Gallery";
import About from "../components/About";
import { ImSpinner2 } from "react-icons/im";
import Amenities from "../components/amenities";
import Table from "../components/Table";



const IndexPage = () => {
  const [state, setstate] = React.useState(false);

  React.useEffect(() => {
    setstate(true);
  }, [state]);

  const displayLogoAtLod = () => {
    return (
      <div css={load}>
        <div>
          loading..
          <br />
          <ImSpinner2 style={{ animation: `spin 0.8s linear infinite` }} />
        </div>
      </div>
    );
  };

  const displayLayout = () => {
    return (
      <>
        <Layout title="Sumadhura Folium Whitefield, Bangalore/Price/Reviews/ by Sumadhura Group">
          <div css={HeroStyle}>
            <HeroImage />
          </div>
          <About/>
          <Table/>
          <FloorPlanComponet />
          <Amenities/>
          <GalleryComponent />
        </Layout>
      </>
    );
  };

  return <div>{state === false ? displayLogoAtLod() : displayLayout()}</div>;
};

export default IndexPage;

const HeroStyle = css`
  padding-top: 5vh;
`;

const load = css`
  padding-top: 30vh;
  text-align: center;
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  svg {
    font-size: 6vh;
  }
`;
